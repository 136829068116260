import React from "react";
import { AspectRatio, Box } from "@chakra-ui/react";

const VideoCard = ({ video }) => {
    return (
        <Box h="auto" w="100%">
            <Box boxShadow="primary">
                <AspectRatio ratio={16 / 9}>
                    <iframe src={video.embed_url} allowFullScreen min-width="100%" height="100%" width="100%" />
                </AspectRatio>
            </Box>
        </Box>
    );
};

export default VideoCard;

import React from "react";
import { Container, Grid, GridItem } from "@chakra-ui/react";
import { MerchandiseCard } from "..";

const MerchandiseContainer = ({ content }) => {
    const { dashboard_cards } = content;

    return (
        <Container maxW="container.xl">
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                {dashboard_cards &&
                    dashboard_cards.map((card, index) => {
                        return (
                            <GridItem key={index} colSpan={{ base: 12, sm: 6, md: 4, lg: 3 }}>
                                <MerchandiseCard
                                    caption={card.dashboard_card_caption}
                                    imageId={card.dashboard_card_assetzen_image_id}
                                    link={card.dashboard_card_link}
                                    subtitle={card.dashboard_card_subtitle}
                                    title={card.dashboard_card_title}
                                />
                            </GridItem>
                        );
                    })}
            </Grid>
        </Container>
    );
};

export default MerchandiseContainer;

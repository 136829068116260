import React from "react";
import { HeroWrapper } from "../";
import { B2BInfoSection } from "../../Sections";

const B2BHero = ({ content }) => {
    return (
        <HeroWrapper backgroundImage={content.homepage.hero_section_assetzen_image_id}>
            <B2BInfoSection
                header={content.b2b.form_header || content.homepage.b2b_fallback_form_header}
                copy={
                    content.b2b.form_copy.length > 0 ? content.b2b.form_copy : content.homepage.b2b_fallback_form_copy
                }
                subheader={content.b2b.form_subheader || content.homepage.b2b_fallback_form_subheader}
                emailDomain={content.b2b.form_email_domain}
                disclaimer={content.b2b.form_disclaimer || content.homepage.b2b_fallback_form_disclaimer}
                companyName={content.b2b.company_name}
            />
        </HeroWrapper>
    );
};

export default B2BHero;

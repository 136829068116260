import React from "react";
import NextLink from "next/link";
import { Heading, Link, Spacer, Text } from "@chakra-ui/react";
import { Card } from "../../../../../common";
import { generateAssetZenURL } from "../../../../../../util/helpers";

const MerchandiseCard = ({ caption, imageId, link, subtitle, title }) => {
    const cardContent = (
        <Card variant="dashboard" aspectRatio={4 / 5} bgImage={generateAssetZenURL(imageId, "assetzen", 447, 595)}>
            <Spacer />
            {subtitle && (
                <Text color="white" fontWeight="semibold" fontSize="sm">
                    {subtitle}
                </Text>
            )}
            {title && (
                <Heading color="white" fontWeight="bold" fontSize="lg">
                    {title}
                </Heading>
            )}
            {caption && (
                <Text color="white" fontSize="sm">
                    {caption}
                </Text>
            )}
        </Card>
    );

    return (
        <React.Fragment>
            {!!link ? (
                <NextLink href={link} passHref>
                    <Link>{cardContent}</Link>
                </NextLink>
            ) : (
                cardContent
            )}
        </React.Fragment>
    );
};

export default MerchandiseCard;

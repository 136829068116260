import React from "react";
import { RichText } from "prismic-reactjs";
import { Box, Container, Flex, Grid, GridItem, Heading } from "@chakra-ui/react";
import { SignUpButton } from "../../../../../common";
import { VideoCard } from "../";

const VacayouInfoContainer = (props) => {
    const { header, subheader, copy, video, darkMode, hasSignUpButton } = props;

    return (
        <Container maxW="container.xl">
            <Flex
                height="100%"
                direction={{ base: "column", lg: "row" }}
                justifyContent={{ base: "center" }}
                alignItems={{ base: "center" }}
            >
                <Grid templateColumns="repeat(12, 1fr)" gap={{ base: 0, md: 12 }}>
                    <GridItem
                        colSpan={{ base: 12, lg: 6 }}
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                        <Heading
                            size="lg"
                            color={darkMode ? "prussianBlue" : "white"}
                            mb={4}
                            textAlign={{ base: "center", lg: "left" }}
                        >
                            {header}
                        </Heading>
                        {subheader && (
                            <Heading
                                size="md"
                                color={darkMode ? "prussianBlue" : "white"}
                                mb={4}
                                textAlign={{ base: "center", lg: "left" }}
                            >
                                {subheader}
                            </Heading>
                        )}
                        <Box
                            textAlign={{
                                base: "center",
                                lg: "left",
                            }}
                            color={darkMode ? "prussianBlue" : "white"}
                            mb={{ base: 5, md: 0 }}
                        >
                            {RichText.render(copy)}
                        </Box>
                        {hasSignUpButton && (
                            <Box display={{ base: "none", lg: "block" }} mt={5}>
                                <SignUpButton color="prussianBlue" colorScheme="platinum" />
                            </Box>
                        )}
                    </GridItem>
                    <GridItem colSpan={{ base: 12, lg: 6 }}>
                        <Flex
                            h="100%"
                            w={{
                                base: "100%",
                                md: "75%",
                                lg: "100%",
                            }}
                            direction="column"
                            mx="auto"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <VideoCard video={video} />
                            {hasSignUpButton && (
                                <Box display={{ base: "block", lg: "none" }} mt={5}>
                                    <SignUpButton color="prussianBlue" colorScheme="platinum" />
                                </Box>
                            )}
                        </Flex>
                    </GridItem>
                </Grid>
            </Flex>
        </Container>
    );
};

export default VacayouInfoContainer;

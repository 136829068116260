import { useEffect } from "react";

import { useRouter } from "next/router";

import {
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";

const WelcomeMessage = ({ content }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const router = useRouter();
    const { welcome } = router.query;

    useEffect(() => {
        if (welcome) {
            onOpen();
        }
    }, []);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
            <ModalOverlay />

            <ModalContent overflow="hidden" sx={{ minWidth: "320px" }}>
                <Flex
                    direction={{ base: "column", md: "row" }}
                    bgImage="https://i.assetzen.net/i/OdpNzSKxMnSU/w:413/h:319/q:70.jpg"
                    bgPosition="center"
                    bgRepeat="no-repeat"
                    bgSize="cover"
                >
                    <Box
                        h={{ base: "300px", md: "auto" }}
                        w={{ base: "100%", md: "50%" }}
                        bgImage={
                            "https://i.assetzen.net/i/" +
                            content.results[0].data.welcome_message_assetzen_image_id +
                            "/w:576/h:434/q:70.jpg"
                        }
                        bgPosition="center"
                        bgRepeat="no-repeat"
                        bgSize="cover"
                    />
                    <Box h="100%" w={{ base: "100%", md: "50%" }}>
                        <ModalHeader
                            color="prussianBlue"
                            fontFamily="Lato"
                            fontWeight="700"
                            fontSize="2xl"
                            textAlign="center"
                            pt={10}
                        >
                            {content.results[0].data.welcome_message_header}
                        </ModalHeader>

                        <ModalCloseButton color="prussianBlue" />

                        <ModalBody color="prussianBlue" textAlign="center" pb={16}>
                            {content.results[0].data.welcome_message_copy}
                        </ModalBody>
                    </Box>
                </Flex>
            </ModalContent>
        </Modal>
    );
};

export default WelcomeMessage;

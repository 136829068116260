import React from "react";
import { RichText } from "prismic-reactjs";
import { AspectRatio, Box, Container, Grid, Heading, Image, useBreakpointValue } from "@chakra-ui/react";
import { SignUpButton } from "../../../../../common";
import { BenefitsCard } from "../";
import { generateAssetZenURL } from "../../../../../../util/helpers";

const BenefitsContainer = (props) => {
    const { header, subheader, copy, benefits } = props;

    return (
        <Container maxW="container.xl">
            <Box>
                <Heading variant="cursive" color="prussianBlue" mb={16}>
                    {header}
                </Heading>
            </Box>
            {benefits &&
                benefits.map((benefit, i) => {
                    return (
                        <Grid
                            key={i}
                            templateColumns={{
                                base: "repeat(1, 1fr)",
                                md: "repeat(2, 1fr)",
                            }}
                            gap={12}
                            mb={{
                                base: i < benefits.length - 1 ? 10 : 0,
                                md: i < benefits.length - 1 ? 28 : 0,
                            }}
                        >
                            <Box order={{ base: 1, md: i % 2 === 0 ? 1 : 2 }} mb={{ base: 6, md: 0 }}>
                                <Heading size="xs" color="prussianBlue" textTransform="none" fontWeight="normal" mb={1}>
                                    {benefit.benefit_subheader}
                                </Heading>
                                <Heading size="md" color="prussianBlue" mb={7}>
                                    {benefit.benefit_header}
                                </Heading>
                                <Box color="prussianBlue">{RichText.render(benefit.benefit_copy)}</Box>
                                <Box mt={3}>
                                    <SignUpButton color="sand" colorScheme="gold" />
                                </Box>
                            </Box>
                            {benefit.benefit_card_2_assetzen_image_id !== null ? (
                                <Grid
                                    templateColumns={{
                                        base: "repeat(1, 1fr)",
                                        sm: "repeat(2, 1fr)",
                                        md: "repeat(1, 1fr)",
                                        lg: "repeat(2, 1fr)",
                                    }}
                                    gap={{ base: 6, xl: 12 }}
                                    order={{
                                        base: 2,
                                        md: i % 2 === 0 ? 2 : 1,
                                    }}
                                >
                                    <BenefitsCard
                                        header={benefit.benefit_card_1_header}
                                        subheader={benefit.benefit_card_1_subheader}
                                        image={benefit.benefit_card_1_assetzen_image_id}
                                        display={true}
                                    />
                                    <BenefitsCard
                                        header={benefit.benefit_card_2_header}
                                        subheader={benefit.benefit_card_2_subheader}
                                        image={benefit.benefit_card_2_assetzen_image_id}
                                        display={useBreakpointValue({
                                            base: false,
                                            sm: true,
                                            md: false,
                                            lg: true,
                                        })}
                                    />
                                </Grid>
                            ) : (
                                <Grid
                                    templateColumns="repeat(1, 1fr)"
                                    order={{
                                        base: 2,
                                        md: i % 2 === 0 ? 2 : 1,
                                    }}
                                >
                                    <AspectRatio ratio={{ base: 1, sm: 1.5 }}>
                                        <Image
                                            rounded="lg"
                                            boxShadow="primary"
                                            src={generateAssetZenURL(
                                                benefit.benefit_card_1_assetzen_image_id,
                                                "assetzen"
                                            )}
                                        />
                                    </AspectRatio>
                                </Grid>
                            )}
                        </Grid>
                    );
                })}
        </Container>
    );
};

export default BenefitsContainer;

import React from "react";
import { Box } from "@chakra-ui/react";
import { HeroWrapper } from "../";
import { VacayouInfoSection } from "../../Sections";

const PreviewHero = ({ content }) => {
    return (
        <HeroWrapper backgroundImage={content.hero_section_assetzen_image_id}>
            <Box py={{ base: 10, lg: 16 }}>
                <VacayouInfoSection
                    header={content.vacayou_info_header}
                    subheader={content.vacayou_info_subheader}
                    copy={content.vacayou_info_copy}
                    video={content.vacayou_info_video}
                    hasSignUpButton
                />
            </Box>
        </HeroWrapper>
    );
};

export default PreviewHero;

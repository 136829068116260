import React from "react";
import { RichText } from "prismic-reactjs";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Container,
    Heading,
} from "@chakra-ui/react";

const FaqsContiner = (props) => {
    const { copy, faqs, header } = props;

    return (
        <Container maxW="container.md">
            <Box>
                <Heading size="md" color="prussianBlue" textAlign="center" mb={4}>
                    {header}
                </Heading>
                <Box color="prussianBlue" textAlign="center" mb={5}>
                    {RichText.render(copy)}
                </Box>
            </Box>
            <Box>
                <Accordion allowToggle>
                    {faqs &&
                        faqs.map((faq, i) => {
                            return (
                                <AccordionItem key={i}>
                                    <Box as="h2">
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left" py={2}>
                                                {faq.question}
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </Box>
                                    <AccordionPanel pb={4}>{RichText.render(faq.answer)}</AccordionPanel>
                                </AccordionItem>
                            );
                        })}
                </Accordion>
            </Box>
        </Container>
    );
};

export default FaqsContiner;

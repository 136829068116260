import React from "react";
import { Box } from "@chakra-ui/react";
import { B2BHero } from "../../Heroes";
import { BenefitsSection, VacayouInfoSection } from "../../Sections";
import { EmailCaptureTile } from "../../../Global";

const B2BContainer = ({ content }) => {
    return (
        <React.Fragment>
            <Box pb={{ base: 10, md: 14 }}>
                <B2BHero content={content} />
            </Box>
            <Box pb={{ base: 10, md: 14 }}>
                <VacayouInfoSection
                    header={content.homepage.vacayou_info_header}
                    subheader={content.homepage.vacayou_info_subheader}
                    copy={content.homepage.vacayou_info_copy}
                    video={content.homepage.vacayou_info_video}
                    darkMode
                />
            </Box>
            <Box pb={{ base: 10, md: 14 }}>
                <BenefitsSection
                    header={content.homepage.benefits_section_header}
                    subheader={content.homepage.benefits_section_subheader}
                    copy={content.homepage.benefits_section_copy}
                    benefits={content.homepage.benefits_section}
                />
            </Box>
            <Box>
                <EmailCaptureTile />
            </Box>
        </React.Fragment>
    );
};

export default B2BContainer;

import { AspectRatio, Box, Flex, Heading, Spacer } from "@chakra-ui/react";
import { BackgroundGradient } from "../../../../../common";
import { constants } from "../../../../../../util/constants";
import { generateAssetZenURL } from "../../../../../../util/helpers";

const BenefitsCard = (props) => {
    const { header, subheader, image, display } = props;

    const { backgroundSettings } = constants;

    return (
        <Box sx={{ display: display ? "block" : "none" }}>
            <AspectRatio ratio={1 / 1.25}>
                <Flex
                    direction="column"
                    rounded="lg"
                    boxShadow="primary"
                    bgImage={generateAssetZenURL(image)}
                    {...backgroundSettings}
                    p={4}
                >
                    <BackgroundGradient variant="card" />
                    <Spacer />
                    <Box zIndex="docked" textAlign="left" width="100%">
                        <Heading as="h4" color="white" size="sm" textTransform="none">
                            {subheader}
                        </Heading>
                        <Heading size="md" fontWeight="900" color="white">
                            {header}
                        </Heading>
                    </Box>
                </Flex>
            </AspectRatio>
        </Box>
    );
};

export default BenefitsCard;

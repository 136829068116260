import { Box } from "@chakra-ui/react";
import { MembershipInfo } from "components/common";

const MembershipSection = ({ cards, copy, header, subheader }) => {
    return (
        <Box
            bgImage="https://i.assetzen.net/i/74e7yrQpaabf/w:1280/h:624/q:70.jpg"
            bgPosition="center"
            bgSize="cover"
            bgRepeat="no-repeat"
            position="relative"
        >
            <Box
                h="100%"
                w="100%"
                position="absolute"
                background="linear-gradient(rgba(16, 54, 92, 0.8), rgba(16, 54, 92, 0.8))"
            />
            <MembershipInfo cards={cards} copy={copy} header={header} subheader={subheader} />
        </Box>
    );
};

export default MembershipSection;

import React from "react";
import { Box, Flex, Heading, Center } from "@chakra-ui/react";
import { RichText } from "prismic-reactjs";
import { Button, Hero } from "../../../../common";
import { generateAssetZenURL } from "../../../../../util/helpers";

const DashboardHero = ({ copy, header, imageId, buttonLink, buttonLabel }) => {
    return (
        <Hero
            aspectRatio={{ base: 1, md: 2 / 1, lg: 3 / 1 }}
            bgImage={{
                base: generateAssetZenURL(imageId, "assetzen", 767, 767),
                md: generateAssetZenURL(imageId, "assetzen", 1200, 400),
            }}
        >
            <Flex h="100%" w="100%" direction="column" alignItems="center" justifyContent="center" textAlign="center">
                <Box color="white" fontSize="40px" fontWeight="bold" textTransform="uppercase">
                    {RichText.render(header)}
                </Box>
                <Box color="white" fontSize="20px">
                    {RichText.render(copy)}
                </Box>
                {buttonLink && buttonLabel && (
                    <Center my={4}>
                        <Button
                            href={buttonLink}
                            label={buttonLabel}
                            color="sand"
                            colorScheme="gold"
                            border="1px solid white"
                        />
                    </Center>
                )}
            </Flex>
        </Hero>
    );
};

export default DashboardHero;

import React, { useState } from "react";
import { RichText } from "prismic-reactjs";
import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Icon,
    Input,
    Text,
    Stack,
    FormControl,
    FormErrorMessage,
} from "@chakra-ui/react";
import { BsMouse } from "react-icons/bs";
import { CgSmartphone } from "react-icons/cg";
import { useRouter } from "next/router";
import { useAuth } from "@/services/hooks/useAuth";

const B2BInfoContainer = (props) => {
    const { header, copy, subheader, emailDomain, disclaimer, companyName } = props;
    const router = useRouter();
    const { registerWithEmailOnly } = useAuth();

    const [formValue, setFormValue] = useState("");
    const [formError, setFormError] = useState({ isError: false, message: "" });
    const [formSubmitting, setFormSubmitting] = useState(false);

    const HtmlSerializer = (type, element, content, children, key) => {
        if (content && content.includes("{% company_name %}")) {
            return content.replace("{% company_name %}", companyName);
        }
        return null;
    };

    const formattedPlainText = (header) => {
        return header.replace("{% company_name %}", companyName);
    };

    const handleChange = (event) => {
        setFormValue(event.target.value);
    };

    const handleSubmit = async (e) => {
        // Validate email domain
        e.preventDefault();
        setFormSubmitting(true);
        setFormError({ isError: false, message: "" });
        const emailParts = formValue.split("@");
        const orgAlias = router.query.uid;
        const fetchedUser = await fetch(`/api/firebase/getUserByEmail/${formValue}`);
        if (emailParts[1] === emailDomain) {
            // Supplied email domain matches the whitelisted email domain
            if (fetchedUser.status === 400) {
                // Account does not exist
                let registrationResponse = await registerWithEmailOnly(formValue, orgAlias);
                if (registrationResponse.emailSent == true) {
                    // Direct to success page
                    router.push(`/${orgAlias}/success`);
                } else {
                    // Display error
                    setFormSubmitting(false);
                    setFormError({ isError: true, message: registrationResponse.error.message });
                }
            } else if (fetchedUser.status === 200) {
                // Account already exists
                setFormSubmitting(false);
                setFormError({ isError: true, message: "This account already exists. Please login." });
            } else {
                setFormSubmitting(false);
                setFormError({ isError: true, message: fetchedUser.body.message });
            }
        } else {
            // Error with "Invalid email domain"
            setFormSubmitting(false);
            setFormError({ isError: true, message: "You must use your " + emailDomain + " email address." });
        }
    };

    return (
        <Container maxW="container.sm" py={{ base: 5, lg: 8 }} px={0}>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={10}>
                <Box
                    as="form"
                    bg="platinum.500"
                    boxShadow="primary"
                    textAlign="center"
                    rounded="lg"
                    w="100%"
                    p={{ base: 6, md: 9 }}
                    overflow="hidden"
                    onSubmit={handleSubmit}
                >
                    <Heading color="prussianBlue" size="md" mb={6}>
                        {formattedPlainText(header)}
                    </Heading>
                    <Box color="prussianBlue" textAlign="left" mb={6}>
                        <RichText render={copy} htmlSerializer={HtmlSerializer} />
                    </Box>
                    <Heading color="prussianBlue" fontWeight="bold" size="sm" textTransform="none" mb={6}>
                        {subheader}
                    </Heading>
                    <FormControl isInvalid={formError.isError}>
                        <Flex direction={{ base: "column", md: "row" }} mb={6}>
                            <Box flexGrow={1} mr={{ base: 0, md: 3 }} mb={{ base: 3, md: 0 }}>
                                <Input
                                    value={formValue}
                                    bgColor="white"
                                    placeholder={"name@" + emailDomain}
                                    onChange={handleChange}
                                    errorBorderColor="crimson"
                                />
                                <FormErrorMessage>{formError.message}</FormErrorMessage>
                            </Box>
                            <Box flexGrow={0}>
                                <Button
                                    isLoading={formSubmitting}
                                    colorScheme="gold"
                                    isFullWidth={{ base: true, md: false }}
                                    type="submit"
                                >
                                    Get Started
                                </Button>
                            </Box>
                        </Flex>
                    </FormControl>
                    <Box>
                        <Text color="prussianBlue" fontStyle="italic">
                            {formattedPlainText(disclaimer)}
                        </Text>
                    </Box>
                </Box>
                <Box textAlign="center">
                    <Icon display={{ base: "none", md: "inline" }} color="white" fontSize="3xl" as={BsMouse} />
                    <Icon display={{ base: "inline", md: "none" }} color="white" fontSize="3xl" as={CgSmartphone} />
                    <Text color="white" fontStyle="italic">
                        Scroll to Learn More
                    </Text>
                </Box>
            </Stack>
        </Container>
    );
};

export default B2BInfoContainer;

import { Box, Container } from "@chakra-ui/react";
import { Hero } from "../../../../common";
import { generateAssetZenURL } from "../../../../../util/helpers";

const HeroSection = ({ backgroundImage, children }) => {
    return (
        <Box position="relative">
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    top: "0",
                    position: "absolute",
                    background: "linear-gradient(to bottom, transparent, #10365C 95%, #10365C)",
                    zIndex: "10",
                }}
            />
            <Hero
                aspectRatio="none"
                bgImage={{
                    base: generateAssetZenURL(backgroundImage, "assetzen", 767, 767),
                    md: generateAssetZenURL(backgroundImage, "assetzen", 1200, 400),
                }}
            >
                <Container maxWidth="container.xl" variant="hero" zIndex={20}>
                    {children}
                </Container>
            </Hero>
        </Box>
    );
};

export default HeroSection;

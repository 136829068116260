import React from "react";
import { Box } from "@chakra-ui/react";
import { DashboardHero } from "../../Heroes";
import { MerchandiseSection, WelcomeMessage } from "../../Sections";
import { EmailCaptureTile } from "../../../Global";

const DashboardContainer = ({ content, welcomeMessage }) => {
    return (
        <React.Fragment>
            <WelcomeMessage content={welcomeMessage} />
            <Box pb={10}>
                <DashboardHero
                    copy={content.dashboard_hero_copy}
                    header={content.dashboard_hero_header}
                    imageId={content.dashboard_hero_assetzen_image_id}
                    buttonLink={content.dashboard_hero_button_link}
                    buttonLabel={content.dashboard_hero_button_text}
                />
            </Box>
            <Box pb={10}>
                <MerchandiseSection content={content} />
            </Box>
            <Box>
                <EmailCaptureTile />
            </Box>
        </React.Fragment>
    );
};

export default DashboardContainer;

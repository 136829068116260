import React from "react";
import { Box } from "@chakra-ui/react";
import { PreviewHero } from "../../Heroes";
import { BenefitsSection, FaqsSection, MembershipSection } from "../../Sections";
import { EmailCaptureTile } from "../../../Global";

const PreviewContainer = ({ content }) => {
    return (
        <React.Fragment>
            <Box pb={{ base: 10, md: 14 }}>
                <PreviewHero content={content} />
            </Box>
            <Box pb={{ base: 10, md: 14 }}>
                <BenefitsSection
                    header={content.benefits_section_header}
                    subheader={content.benefits_section_subheader}
                    copy={content.benefits_section_copy}
                    benefits={content.benefits_section}
                />
            </Box>
            <Box pb={{ base: 10, md: 14 }}>
                <MembershipSection
                    header={content.membership_section_header}
                    subheader={content.membership_section_subheader}
                    copy={content.membership_section_copy}
                    cards={content.membership_section_cards}
                />
            </Box>
            <Box pb={{ base: 10, md: 14 }}>
                <FaqsSection
                    header={content.faq_section_header}
                    subheader={content.faq_section_subheader}
                    copy={content.faq_section_copy}
                    faqs={content.faqs}
                />
            </Box>
            <Box>
                <EmailCaptureTile />
            </Box>
        </React.Fragment>
    );
};

export default PreviewContainer;
